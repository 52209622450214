import { RedirectLoggedInToAppLoader } from "~/loaders/redirectLoggedInToApp.server";

export const loader = RedirectLoggedInToAppLoader;

const App: React.FC = () => {
    return (
        <div>
            
        </div>
    );
}
export default App;